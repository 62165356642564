import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import Cookies from 'js-cookie';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Button } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { useAdminGetOrderById } from '../../../../shared/order/hooks/order.hooks';
import AddProduct from '../../../../Containers/admin/product/AddProducts';
import AddVirtualProduct from '../../../../Containers/admin/product/AddVirtualProduct';
import ProductList from '../../../../Containers/admin/product/ProductList';
import OrderStatusFormControl from '../../../../components/Order/select/OrderStatusFormControl';
import OrderAdvStatusFormControle from '../../../../components/Order/select/OrderAdvStatusFormControl';
import DeliveryInfoSummary from '../../../../Containers/admin/order/Summary/DeliveryInfoSummary';

import { ADMIN_ORDER_APP_PATHS } from '../../../../shared/order/constants/path.constants';
import { axiosPatchRequest } from '../../../../utils/axiosRequests';

const useStyles = makeStyles((theme) => ({
  bottom: {
    display: 'grid',
    gridTemplateColumns: '4fr 1fr',
    gap: '1rem',
  },

  leftPart: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  },

  action: {
    padding: theme.spacing(2),
    overflow: 'auto',
    maxHeight: '200px',

    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },

    '& > h4': {
      margin: '0 0 1rem 0',
    },
  },
}));

export default function AdminUpdateOrderPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { orderId } = useParams();

  const { loading, order } = useAdminGetOrderById({ orderId });
  
  // Order products
  const [products, setProducts] = useState([]);

  // Order delivery
  const [selectedDeliveryType, setSelectedDeliveryType] = useState('home');
  const [selectedDeliveryAddress, setSelectedDeliveryAddress] = useState(null);
  const [selectedBillingAddress, setSelectedBillingAddress] = useState(null);
  const [selectedDeliveryDay, setSelectedDeliveryDay] = useState({
    date: moment(),
    formattedDate: moment().format('dddd Do MMMM YYYY'),
    schedule: '9H00 - 17H30',
  });

  // Order status
  const [selectedStatus, setSelectedStatus] = useState('paid');
  const [selectedAdvStatus, setSelectedAdvStatus] = useState('validate');

  const canCreateOrder = !!products.length && !!selectedDeliveryAddress && !!selectedDeliveryDay;

  useEffect(() => {
    if (order) {
      setProducts((order.items ?? []).map((item) => ({
        id: item.productId ?? item._id,
        name: item.name,
        categoryName: item.category,
        subCategoryName: item.subCategory,
        price: item.price,
        tvaRate: item.tvaRate,
        size: item.size,
        quantity: item.quantity,
      })));

      setSelectedDeliveryType(order?.deliveryInfos?.deliveryType ?? 'home');
      setSelectedDeliveryAddress(order?.deliveryInfos?.deliveryAddress ?? null);
      setSelectedBillingAddress(order?.deliveryInfos?.deliveryBillingAddress ?? null);
      setSelectedDeliveryDay({
        date: moment(order?.deliveryInfos?.deliveryDate),
        formattedDate: order?.deliveryInfos?.deliveryDate ? moment(order?.deliveryInfos?.deliveryDate).format('dddd Do MMMM YYYY') : null,
        schedule: order?.deliveryInfos?.deliverySchedule ?? null,
      });

      setSelectedStatus(order.status ?? 'paid');
      setSelectedAdvStatus(order.advStatus ?? 'validate');
    }
  }, [order]);
  
  const handleAddProduct = (product) => {
    if (product.type === 'virtual') return setProducts((prev) => [...prev, product]);

    const isProductAlreadyAdded = products.some((el) => el.id === product.id);

    if (isProductAlreadyAdded) {
      return setProducts((prev) =>
        prev.reduce((acc, curr) => (curr.id === product.id ? [...acc, { ...curr, quantity: curr.quantity + 1 }] : [...acc, curr]),
          []
        )
      );
    }
    return setProducts((prev) => [...prev, { ...product, quantity: 1 }]);
  };

  const handleIncreaseProductQuantity = (productId) => {
    setProducts((prev) =>
      prev.reduce(
        (acc, curr) => (curr.id === productId ? [...acc, { ...curr, quantity: curr.quantity + 1 }] : [...acc, curr]),
        []
      )
    );
  };

  const handleChangeProductQuantity = (productId, quantity) => {
    const newQuantity = quantity > 0 ? quantity : 1;
    setProducts((prev) =>
      prev.reduce(
        (acc, curr) => (curr.id === productId ? [...acc, { ...curr, quantity: newQuantity }] : [...acc, curr]),
        []
      )
    );
  };

  const handleDecreaseProductQuantity = (productId) => {
    setProducts((prev) =>
      prev
        .reduce(
          (acc, curr) => (curr.id === productId ? [...acc, { ...curr, quantity: curr.quantity - 1 }] : [...acc, curr]),
          []
        )
        .filter((product) => product.quantity > 0)
    );
  };

  const handleUpdateOrder = async () => {
    try {
      const token = Cookies.get('jwt');

      const updatedOrder = {
        orderId,
        delivery: {
          type: selectedDeliveryType,
          addresses: {
            deliveryAddress: selectedDeliveryAddress,
            billingAddress: selectedBillingAddress,
          },
          deliveryDay: {
            date: selectedDeliveryDay.date.format('YYYY-MM-DD'),
            schedule: selectedDeliveryDay.schedule,
          },
        },
        status: selectedStatus,
        advStatus: selectedAdvStatus,
        products,
      };

      const response = await axiosPatchRequest(`/admin/order/update`, token, updatedOrder);

      const updatedOrderId = response.data.data.order._id;

      window.location.href = ADMIN_ORDER_APP_PATHS.ORDER_SUMMARY.replace(':orderId', updatedOrderId);
    } catch (error) {
      console.log(error)
      if (error.response?.data?.message) enqueueSnackbar(error.response.data.message, { variant: 'error' });
      else enqueueSnackbar('Une erreur est survenue lors de la mise à jour de la commande', { variant: 'error' });
    }
  };

  return (
    <Container maxWidth="xl">
      {loading && <Skeleton variant="rect" width="100%" height={50} />}
      {!loading && (
        <>
          <h1>Mise à jour de la commande #{orderId}</h1>
          <AddProduct mb={1} handleAddProduct={handleAddProduct} />
          <AddVirtualProduct mb={1} handleAddProduct={handleAddProduct} />
          <ProductList
            mb={1}
            products={products}
            handleIncrease={handleIncreaseProductQuantity}
            handleChangeQuantity={handleChangeProductQuantity}
            handleDescrease={handleDecreaseProductQuantity}
          />

          <section className={classes.bottom}>
            <DeliveryInfoSummary
                lastOrder={order}
                addresses={order.owner.address ?? []}
                selectedDeliveryType={selectedDeliveryType}
                selectedDeliveryAddress={selectedDeliveryAddress}
                selectedBillingAddress={selectedBillingAddress}
                selectedDeliveryDay={selectedDeliveryDay}
                setSelectedDeliveryType={setSelectedDeliveryType}
                setSelectedDeliveryAddress={setSelectedDeliveryAddress}
                setSelectedBillingAddress={setSelectedBillingAddress}
                setSelectedDeliveryDay={setSelectedDeliveryDay}
              />

            <section className={classes.leftPart}>
              <Paper className={classes.action}>
                <h4>Statut de la commande :</h4>
                <div>
                  <OrderStatusFormControl
                    selectedStatus={selectedStatus}
                    handleChange={(event) => setSelectedStatus(event.target.value)}
                  />
                  <OrderAdvStatusFormControle
                    selectedAdvStatus={selectedAdvStatus}
                    handleChange={(event) => setSelectedAdvStatus(event.target.value)}
                  />
                </div>
              </Paper>
              <Button
                disabled={!canCreateOrder}
                type="button"
                variant="contained"
                color="primary"
                onClick={handleUpdateOrder}
              >
                Mettre à jour
              </Button>
            </section>
          </section>
        </>
      )}
    </Container>
  );
}
